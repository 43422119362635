@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 9%;
    --foreground: 0 0% 98%;

    --card: 218 15% 12%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 6%;
    --popover-foreground: 0 0% 98%;

    --dialog: 0 0% 6%;
    --dialog-foreground: 0 0% 98%;

    --primary: 186 66% 72%;
    --primary-foreground: 0 0% 9%;

    --secondary: 39 100% 68%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 14%;
    --muted-foreground: 165 2% 66%;

    --accent: 0 0% 6%;
    --accent-foreground: 0 0% 98%;

    --destructive: 348 85% 54%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14%;
    --input: 0 0% 16%;
    --ring: 0 0% 98%;
    --frozen: 0 0% 14%;
  }

  .dark {
    --background: 0 0% 9%;
    --foreground: 0 0% 98%;

    --card: 218 15% 12%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 6%;
    --popover-foreground: 0 0% 98%;

    --dialog: 0 0% 6%;
    --dialog-foreground: 0 0% 98%;

    --primary: 186 66% 72%;
    --primary-foreground: 0 0% 9%;

    --secondary: 39 100% 68%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 14%;
    --muted-foreground: 165 2% 66%;

    --accent: 0 0% 6%;
    --accent-foreground: 0 0% 98%;

    --destructive: 348 85% 54%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14%;
    --input: 0 0% 16%;
    --ring: 0 0% 98%;
    --frozen: 0 0% 14%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background font-body tracking-wide text-foreground;
  }
}
